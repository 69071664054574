<template>
  <div>
    <YessLinkList
      v-if="showYessLinkDialog"
      :profiles="profiles"
      @selectProfile="selectProfile"
      @addNewLink="addNewLink"
      @closed="close"
    />
    <YessLinkDialog
      v-if="selectedProfile !== null"
      :yess-profile="selectedProfile"
      :organization="organization"
      @closed="close"
    />
    <v-card class="mx-auto">
      <v-img
        height="200px"
        class="grey"
      />
      <v-card-title>{{ organization.label }}</v-card-title>
      <v-card-subtitle>{{ organization.description }}</v-card-subtitle>
      <v-card-text>
        <v-row
          dense
          align="center"
        >
          <v-col
            cols="12"
            sm="auto"
          >
            <v-chip
              small
              outlined
              @click="copyUUID"
            >
              <v-avatar left>
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-avatar>
              {{ $t('UUID') }}
            </v-chip>
            <span class="pl-2 caption">{{ organization.uuid }}</span>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            v-if="organization.ssn !== null && typeof organization.ssn !== 'undefined'"
          >
            <v-chip
              small
              outlined
              @click="copySSN"
            >
              <v-avatar left>
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-avatar>
              {{ this.$t('SSN') }}
            </v-chip>
            <span class="pl-2 caption">{{ organization.ssn }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          link
          target="_blank"
          :href="'https://admin.salescloud.is/company/' + organization.uuid + '/switch'"
        >
          {{ $t('switch') }}
        </v-btn>
        <v-spacer v-if="organizationUUID === 'f95bbe80-b9c2-40eb-943d-95d696c1acfb'" />
        <v-btn
          @click="linkToYess"
          v-if="organizationUUID === 'f95bbe80-b9c2-40eb-943d-95d696c1acfb' && !hasYessProfile"
          color="primary"
          link
        >
          {{ $t('linkToYess') }}
        </v-btn>
        <v-btn
          @click="linkToYess"
          v-if="organizationUUID === 'f95bbe80-b9c2-40eb-943d-95d696c1acfb' && hasYessProfile"
          color="error"
          link
        >
          {{ $t('yessProfile') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          link
          target="_blank"
          :href="'https://admin.salescloud.is/company/' + organization.uuid + '/edit'"
        >
          {{ $t('manage') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import YessLinkDialog from './yess/YessLinkDialog'
import YessLinkList from './yess/YessLinkDialogList'

export default {
	name: 'OrganizationCard',
  props: {
    organization: {
      type: Object,
      default: () => ({})
    }
  },
	components: {
		YessLinkDialog,
		YessLinkList
	},
	data: () => ({
		building: false,
		showYessLinkDialog: false,
		selectedProfile: null,
    copyingOrganizationUUID: false,
    copyingOrganizationSSN: false
	}),
	methods: {
		addNewLink() {
			this.selectedProfile = {}
		},
		selectProfile(profile) {
			this.showYessLinkDialog = false
			this.selectedProfile = profile
		},
		close() {
			this.showYessLinkDialog = false
			this.selectedProfile = null
		},
		async copyUUID() {
      this.copyingOrganizationUUID = true
			await this.writeToClipboard(String(this.organization.uuid))
      this.copyingOrganizationUUID = false
		},
		async copySSN() {
		  this.copyingOrganizationSSN = true
      await this.writeToClipboard(String(this.organization.ssn))
      this.copyingOrganizationSSN = false
		},
    writeToClipboard(text) {
      window.navigator.clipboard.writeText(text).then(result => {
        return !!result
      }).catch(e => {
        return false
      })
    },
		linkToYess() {
			this.showYessLinkDialog = true
		}
	},
	computed: {
		organizationUUID() {
			return this.$store.state.organization.uuid
		},
		profiles() {

			if(this.$store.state.yessProfiles !== null) {
				return this.$store.state.yessProfiles.filter(profile => profile !== null && profile.organization.uuid === this.organization.uuid)
			}

			return null
		},
		hasYessProfile() {

			if(this.$store.state.yessProfiles !== null && this.$store.state.yessProfiles.length > 0) {
				for(let i in this.$store.state.yessProfiles) {

					if(this.$store.state.yessProfiles[i] === null) {
						continue
					}

					if(this.$store.state.yessProfiles[i].organization.uuid === this.organization.uuid) {
						return true
					}
				}
			}

			return false
		}
	}
}
</script>
