<template>
  <v-dialog
    max-width="500px"
    :value="true"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>Yess Link List</v-card-title>
      <v-list>
        <template v-for="profile in profiles">
          <v-list-item
            @click="selectProfile(profile)"
            :key="profile.slug"
          >
            <v-list-item-content>
              <v-list-item-title>{{ profile.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ profile.slug }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-card-actions>
        <v-btn
          block
          @click="addNewLink"
        >
          Add new
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    'name': 'YessLinkList',
    props: {
      profiles: {
        type: Array,
        default: () => []
      }
    },
    methods: {
        close() {
          this.$emit('closed')
        },
        selectProfile(profile) {
            this.$emit('selectProfile', profile)
        },
        addNewLink() {
            this.$emit('addNewLink')
        }
    }
}
</script>
